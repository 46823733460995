import React from 'react';
import SectionTitle from "../common/section-title";
import Button from "../common/button";

import "./profile.css";

const Profile = () => {
  return (
    <div className="profile-container">
      <SectionTitle title="About me" />
      <div className="container">
        <div className="description">
          <p>I'm Nerina, a Full-Stack developer based in Buenos Aires, Argentina.</p>
          <p>
            I have several years of experience developing web and mobile applications 
            with JavaScript (ReactJS, React Native and NodeJS).
          </p>
          <p>Is it interesting for you? Lucky day :)</p>
          <p>
            I work at <a href="https://www.sysgarage.com" className="link" target="_blank" rel="noopener noreferrer"> sysgarage </a> 
            and we do Team Augmentation. So if you're looking for a JS team or professional 
            who pays attention to every detail just contact us and let's get started!
          </p>
        </div>
        <div className="section-container">
          <a href="mailto:projects@sysgarage.com?Subject=contact%20from%20nerinaserra">
            <Button name="Contact us" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Profile;