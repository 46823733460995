import React from 'react';

const SectionTitle = ({ title }) => {
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      fontSize: "2.5rem",
      margin: "2rem 0",
    }}>
      {title}
    </div>
  );
}

export default SectionTitle;