import React from "react";
import { Link } from "gatsby";
import SectionTitle from "../common/section-title";
import Row from "../common/row";
import Column from "../common/column";

import "./projects.css";

import superlista from '../../images/projects/screen1.png';
import maleva from "../../images/projects/maleva/header.png";
import cookIt from "../../images/projects/cook-it/main.png";

const Projects = () => {
  return (
    <div className="portfolio-container">
      <SectionTitle title="Own projects" />
      <div className="container">
        <Link to="/superlista" className="project">
          <Row>
            <Column>
              <img src={superlista} alt="Superlista" width="150" height="170" />
            </Column>
            <Column>
              <div className="title">Superlista</div>
              <div className="description">Make your grocery list!</div>
              <div className="tags">
                <div className="tag">#Mobile</div>
                <div className="tag">#ReactNative</div>
              </div>
            </Column>
          </Row>
        </Link>
        <Link to="/maleva" className="project">
          <Row>
            <Column>
              <img src={maleva} alt="Maleva" width="150" height="170" />
            </Column>
            <Column>
              <div className="title">Maleva</div>
              <div className="description">An app that speaks for you</div>
              <div className="tags maleva">
                <div className="tag">#Mobile</div>
                <div className="tag">#ReactNative</div>
              </div>
            </Column>
          </Row>
        </Link>
        <Link to="/cookit" className="project">
          <Row>
            <Column>
              <img src={cookIt} alt="Cook It" width="150" height="170" />
            </Column>
            <Column>
              <div className="title">Cook It</div>
              <div className="description">Your favorite recipes always at hand</div>
              <div className="tags cook-it">
                <div className="tag">#Mobile</div>
                <div className="tag">#ReactNative</div>
              </div>
            </Column>
          </Row>
        </Link>
      </div>
    </div>
  );
};

export default Projects;