import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about/about";
import Profile from "../components/profile/profile";
import Welcome from "../components/welcome/welcome";
import Projects from "../components/projects/projects";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Welcome />
    <Profile />
    <About />
    <Projects />
  </Layout>
)

export default IndexPage
