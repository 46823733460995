import React from 'react';

import './welcome.css';

import profile from '../../images/profile.jpg';

const Welcome = () => {
  return (
    <div className="welcome-container">
      <img src={profile} alt="Nerina Serra" className="welcome-image" />
      <div className="welcome-background" />
      <div className="welcome-content">
        <p className="welcome-name">Nerina Serra</p>
        <p className="welcome-description">
          I'm a Full-Stack developer based in Buenos Aires, Argentina.
        </p>
      </div>
    </div>
  );
}

export default Welcome;
