import React from "react";
import SectionTitle from "../common/section-title";
import Work from "./work";
import Skill from "./skill";

import "./about.css";

const About = () => {
  return (
    <div className="about-container">
      <SectionTitle title="Resume" />
      <Work />
      <Skill />
    </div>
  );
};

export default About;