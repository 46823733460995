import React from 'react';

import "./button.css";

const Button = ({ name, onClick }) => {
  return (
    <div onClick={onClick} className="button-primary">
      {name}
    </div>
  );
}

export default Button;