import React from "react";
import Row from "../common/row";
import Column from "../common/column";

const skills = [
  'JavaScript',
  'ReactJS',
  'React Native',
  'NodeJS',
  // 'AngularJS',
  // 'Ionic',
  'HTML',
  'CSS',
  'SQL',
  // 'SASS',
  // 'jQuery',
  // 'GIT',
  // 'Java',
  'Database Design',
  'Databases: MySQL, Oracle'
];

const Skill = () => {
  let skillsList = skills.map(function(item, key) {
    return (
      <div className="item" key={key}>
        <div>{item}</div>
      </div>
    );
  });

  return (
    <div className="skill-container container">
      <Row>
        <Column className="left-column">
          <div className="line" />
          <p className="title">SKILLS</p>
        </Column>
        <Column className="right-column skill-list">
          { skillsList }
        </Column>
      </Row>
    </div>
  );
};

export default Skill;