import React from "react";
import Row from "../common/row";
import Column from "../common/column";

const experience = [
  {
    id: 1,
    title: 'Full-Stack Developer',
    company: 'sysgarage',
    where: 'Remote',
    when: 'November 2016 - Present',
    description: 'Web and Mobile applications development.'
  }, {
    id: 2,
    title: 'Front-End Developer',
    company: 'QwertySoft',
    where: 'La Plata, Buenos Aires, Argentina',
    when: 'May 2016 - December 2016',
    description: 'Web applications development.'
  }, {
    id: 3,
    title: 'Java Jr. Developer',
    company: 'CeSPI - UNLP',
    where: 'La Plata, Buenos Aires, Argentina',
    when: 'June 2014 - May 2016',
    description: 'Web applications development.'
  }
];

const Work = () => {
  let experienceList = experience.map(function(item) {
    return (
      <div className="item" key={item.id}>
        <div className="item-title">{item.title}</div>
        <div className="item-detail">
          <span className="item-company">{item.company}</span>
          <span>, {item.where}, {item.when}</span>
        </div>
        <div className="item-description">{item.description}</div>
      </div>
    );
  });

  return (
    <div className="work-container container">
      <Row>
        <Column className="left-column">
          <div className="line" />
          <p className="title">WORK EXPERIENCE</p>
        </Column>
        <Column className="right-column work-list">
          { experienceList }
        </Column>
      </Row>
    </div>
  );
};

export default Work;